import { fetcher } from "@pdpp/lib-react";
import { Organisation } from "@pdpp/lib-planta";

import { plantaApi } from "../../api/fetch";
import { getNormalisedOrgNodes } from "./transforms";
import { orgDrivers$, orgDriversLoading$, orgNodes$ } from "./state";
import { Driver } from "./types";
import { BadRequest } from "../../api/types.mjs";

export const useGetOrgStructure = fetcher(
  async (_: object, signal) => {
    const data = await plantaApi.jsonInJsonOut<Organisation>(
      `/headcount/organisation-structure`,
      { signal },
    );

    return data;
  },
  {
    keyFn: () => "orgStructure",
    processResult: (result) => {
      const { status, data } = result;
      if (status === "ok") {
        orgNodes$.value = getNormalisedOrgNodes(data.nodes);
      }
      return result;
    },
  },
).useFetch;

export const getOrgDrivers = async (
  orgNodeId: string,
): Promise<Array<Driver>> => {
  try {
    orgDriversLoading$.value = true;
    const data = await plantaApi.jsonInJsonOut<Array<Driver>>(
      `/driver/${orgNodeId}`,
    );

    orgDrivers$.value = data;

    return data;
  } finally {
    orgDriversLoading$.value = false;
  }
};

interface CreatedActivity {
  data: string;
}

type CrateActivityResponse = CreatedActivity | BadRequest;

export const putActivity = async (
  department: string,
  name: string,
  description: string,
): Promise<CrateActivityResponse> => {
  try {
    const response = await plantaApi.jsonInJsonOut<CrateActivityResponse>(
      `/activity`,
      {
        method: "PUT",
        body: JSON.stringify({
          activity: name,
          departmentId: department,
          description,
        }),
      },
    );
    return response;
  } catch (error) {
    // @ts-expect-error error is a Response with known schema
    return { error: (await error.response.json()).error };
  }
};

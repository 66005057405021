declare global {
  interface Window {
    readonly __RUNTIME_CONFIG__: ImportMetaEnv;
  }
}

function get(key: string): string {
  const c = window.__RUNTIME_CONFIG__ ?? {};
  // TODO: unify __RUNTIME_CONFIG__ and Vite environmental variables names
  // `viteKey` exists to load variables when running in package mode
  // that were bundled with Vite, but are referenced internally by name without prefix
  // example: 'Environment'
  const viteKey = key.includes("VITE_") === false ? `VITE_${key}` : key;
  return c[key] !== undefined ? c[key] : import.meta.env[viteKey];
}

export const ENVIRONMENT = get("ENVIRONMENT");
export const SENTRY_RELEASE = get("SENTRY_RELEASE");
export const SENTRY_DSN = get("SENTRY_DSN");
export const VITE_APP_CLIENT_ID = get("VITE_APP_CLIENT_ID");
export const VITE_APP_TENANT_ID = get("VITE_APP_TENANT_ID");
export const VITE_API_URL = get("VITE_API_URL");
export const VITE_TRACKER_URL = get("VITE_TRACKER_URL");
export const WELCOME_HELP_URL = get("WELCOME_HELP_URL");
export const ADMIN_GROUP_IDS = get("ADMIN_GROUP_IDS");

import { TFunction } from "i18next";
import { addDays, differenceInCalendarWeeks } from "date-fns";

import { TransactionType } from "@pdpp/planta-server/dist/api/features/headcount/file/bq.types.mjs";

import { HeadCount } from "../../types/api";
import { fmtHhMm } from "../dates/transforms";
import { Template } from "../overview/types";
import { Driver } from "../org/types";

export function getMinutesFromHeadcount(
  headcounts: ReadonlyArray<number>,
): number {
  let totalHeadcount = 0;

  for (const headCount of headcounts) {
    /**
     * Skip if value if less than zero as
     * value `-1` is used to mark hours/headcounts
     * that are not yet planned
     */
    if (headCount > 0) {
      totalHeadcount += headCount;
    }
  }

  return totalHeadcount * 15;
}

export function getHoursFromHeadCount(
  headCounts: ReadonlyArray<number>,
): number {
  return getMinutesFromHeadcount(headCounts) / 60;
}

/**
 * Returns hours minutes from headCount in format `1h00`
 */
export function getDurationFromHeadCountFormatted(
  headCounts: ReadonlyArray<number>,
): string {
  return fmtHhMm(getMinutesFromHeadcount(headCounts));
}

export function getUsedTemplateName(
  headcount: ReadonlyArray<HeadCount>,
  templates: ReadonlyArray<Template>,
  t: TFunction,
): string {
  // Find the corresponding HeadCount object where sourceId matches any template id
  const matchedTemplate = headcount.find((hc) =>
    templates.some((template) =>
      hc.sourceId?.includes(template.id?.toString() ?? null),
    ),
  );

  if (matchedTemplate && matchedTemplate.sourceType) {
    if (matchedTemplate.sourceType?.[0] === TransactionType.Manual) {
      return t("labels.custom");
    }
    if (
      matchedTemplate.sourceType?.[0] === TransactionType.DayTemplate ||
      matchedTemplate.sourceType?.[0] === TransactionType.Driver
    ) {
      return matchedTemplate.sourceLabel?.[0] ?? "";
    }
  }

  return t("labels.custom");
}

/**
 * Returns how many days a templates has been assigned
 */

export function getUsageTemplate(
  hc: ReadonlyArray<HeadCount>,
  templateId: string,
): number {
  let maxUsageTemplate = 0;
  for (const h of hc) {
    if (h.sourceId !== null && h.sourceId !== undefined) {
      const count = h.sourceId.filter((id) => id === templateId).length; // Count occurrences of templateId
      maxUsageTemplate = Math.max(maxUsageTemplate, count); // Update max count if current count is higher
    }
  }
  return maxUsageTemplate;
}

/**
 * Returns the driver with the given name and subType
 */
export function findDriver(
  drivers: ReadonlyArray<Driver>,
  driverName: string,
  driverSubType: string | null,
): Driver | null {
  //Some drivers doesn't have a subtype in Progga BQ
  if (driverSubType === null) {
    return drivers.find((d) => d.name === driverName) || null;
  }

  return (
    drivers.find(
      (d) => d.name === driverName && d.workloadDriverSubType === driverSubType,
    ) || null
  );
}

export function sumDayHeadcountsByWeek(
  headcounts: readonly number[],
  startDate: Date,
  endDate: Date,
): number[] {
  // Calculate the number of weeks in the range
  const numWeeks = differenceInCalendarWeeks(endDate, startDate) + 1;

  // Initialize an array to store the sums for each week
  const weekSums: number[] = new Array(numWeeks).fill(0);

  for (const [i, hc] of headcounts.entries()) {
    if (hc === -1) {
      continue;
    }

    // Calculate the current date, since hc are ordered by day
    const currentDate = addDays(startDate, i);

    // Get the week index for the current date relative to the start date
    const weekIndex = differenceInCalendarWeeks(currentDate, startDate);

    // Add the headcount to the corresponding week sum
    weekSums[weekIndex] += hc;
  }

  return weekSums;
}

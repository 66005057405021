import { signal, effect } from "@preact-signals/safe-react";

import {
  getShareableSetting,
  setShareableSetting,
} from "../../utils/shareableSetting";
import { ClientBusinessNode, Driver } from "./types";

const KEY = "orgNode";

export const orgId$ = signal(getShareableSetting(KEY) ?? undefined);
export const orgNodes$ = signal<ReadonlyArray<ClientBusinessNode>>([]);
export const orgDrivers$ = signal<ReadonlyArray<Driver>>([]);
export const orgDriversLoading$ = signal(false);

type OrgNodeById = Map<string, ClientBusinessNode>;

export const ORG_NODE_BY_ID: OrgNodeById = new Map();

effect(() => {
  const value = orgId$.value;
  if (value !== undefined) {
    setShareableSetting(KEY, value);
  }
});

// Populates orgNodes Map
effect(() => {
  const orgNodes = orgNodes$.value;

  for (const node of orgNodes) {
    ORG_NODE_BY_ID.set(node.id, node);
  }
});
